<template>
  <div class="test">
    <img src="../assets/imgs/reg_left.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@keyframes run {
  0% {
    transform: translate(0,0);
    opacity: 0;
  }
  25% {
    transform: translate(-10px, 10px);
    opacity: 1;
  }
  50% {
    transform: translate(-20px, 20px);
    opacity: 1;
  }
  75% {
    transform: translate(-30px, 30px);
    opacity: 1;
  }
  100% {
    transform: translate(-40px, 40px);
    opacity: 1;
  }
}
.test {
  margin: 0 auto;
  text-align: center;
  border: 1px solid #000;
  width: 500px;
  height: 300px;
  // .img{
  img {
    animation: run 5s linear infinite;
  }
  // background-image: url("../assets/imgs/reg_left.png");
  // }
}
</style>
